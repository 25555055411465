<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12"> Em construção </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BAlert, BBadge, BCard, BCol, BRow, BButton } from 'bootstrap-vue'

export default {
  name: 'CompanyUserProfile',
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
  },
  data() {
    return {
      title: 'Minha Conta',
      showForm: false,
      representative: {},
    }
  },
}
</script>
